<script>
// eslint-disable-next-line no-undef
const axios = require("@/store/config/axios.config.js").default;

export default {
  name: "TreeNode",
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasChildren() {
      const { children, statusChildren } = this.node;
      return (children && children.length > 0) || statusChildren;
    },
    Children() {
      if (this.node.children && this.node.children.length > 0) {
        return this.node.children;
      } else {
        return this.children;
      }
    },
  },
  data() {
    return {
      showChildren: false,
      children: [],
    };
  },
  methods: {
    async openModal() {
      await this.$store.dispatch("member/req_memberDetails", this.node.id);
      this.$bvModal.show("member-data");
    },
    notify() {
      this.$toastr.e("Feature is in maintenance", "Opps. Somthing Wrong!");
    },
    async getChildren() {
      if (this.Children.length <= 0) {
        const { trees } = await axios.get("affiliate/member-tree", {
          params: {
            children: this.node.id,
          },
        }).then(res => res);
        this.children = trees.children;
      }
      this.showChildren = !this.showChildren;
    },
  },
};
</script>

<template>
  <div class="tree-node">
    <button
      class="botan"
      :class="{ hasChildren: hasChildren }"
      @click="openModal"
    >
      <img
        v-if="hasChildren"
        src="@/assets/images/icons/member.svg"
        alt="member"
        width="15px"
      />
      <img
        v-else
        src="@/assets/images/icons/member-white.png"
        alt="member"
        width="15px"
      />
      {{ node.username }}
      <button
        v-if="hasChildren"
        class="botan showMore"
        @click.stop="getChildren"
      >
        {{ showChildren ? "-" : "+" }}
      </button>
    </button>
    <div class="children" v-if="hasChildren && showChildren">
      <TreeNode
        v-for="(child, index) in Children"
        :key="node.id + index"
        :node="child"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tree-node {
  padding: 5px 0px;
  padding-left: 30px;
  .children {
    margin-top: 30px;
  }
  .hasChildren {
    color: #00c2ff;
  }
  .showMore {
    background-image: linear-gradient(135deg, #0ec5ff 0%, #3964fb 82.81%);
    border-radius: 100vw;
    width: 17px;
    height: 17px;
    padding: 4px;
    padding-bottom: 8px;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
