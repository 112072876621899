<template>
  <div class="affiliatecc">
    <div v-if="Statistic && Statistic.agencyNext" class="affiliate-info-item agency">
      <p>{{ $t("exchange.agency") }}</p>
      <hr />
      <div>
        <div class="agency-img mb-3">
          <template>
            <div v-if="Statistic.agency" class="agency-wrap">
              <img :src="Statistic.agency.image" alt="" />
              <div class="gradient-text">{{ Statistic.agency.name }}</div>
            </div>
            <div v-else class="agency-wrap">
              <img src="@/assets/images/mockup/rocket.png" alt="" />
              <div class="gradient-text">Sonic</div>
            </div>
          </template>
          <img class="upgrade" src="@/assets/images/icons/upgrade.svg" />
          <div v-if="Statistic.agencyNext" class="agency-wrap">
            <img :src="Statistic.agencyNext.image" alt="" />
            <div class="gradient-text">{{ Statistic.agencyNext.name }}</div>
          </div>
        </div>

        <div class="agency-progress" :style="{ '--progress': VolumePercent }">
          <span class="gradient-text">
            {{ $t("affiliate.systemVolumeWeek") }}
          </span>
          <div class="bar">
            <span class="gradient-text">
              {{ parseFloat(Statistic.totalBetSystem).toFixed(0) }} /
              {{ parseFloat(Statistic.agencyNext.total_volume).toFixed(0) }}
            </span>
          </div>
        </div>

        <div class="agency-progress" :style="{ '--progress': UserPercent }">
          <span class="gradient-text">
            {{ $t("affiliate.userVolumeWeek") }}
          </span>
          <div class="bar">
            <span class="gradient-text">
              {{ parseFloat(Statistic.totalBetUser).toFixed(0) }} /
              {{ parseFloat(Statistic.agencyNext.trade_min).toFixed(0) }}
            </span>
          </div>
        </div>

        <div class="agency-progress" :style="{ '--progress': DownlinePercent }">
          <span class="gradient-text">
            {{ $t("affiliate.activeDownlineWeek") }}
          </span>
          <div class="bar">
            <span class="gradient-text">
              {{ parseFloat(Statistic.totalDownLineActive).toFixed(0) }} /
              {{ parseFloat(Statistic.agencyNext.count_f1).toFixed(0) }}
            </span>
          </div>
        </div>
      </div>

      <div class="bonus">
        <p>
          {{ $t("affiliate.totalVolumeSystemLastWeek") }}:
          <span>
            {{
              TruncateToDecimals2(+Statistic.totalBetUserLastWeek, "", 2)
            }}$</span>
        </p>
        <p>
          {{ $t("affiliate.commissionReceiveLastWeek") }}
          <span>
            {{
              TruncateToDecimals2(+Statistic.commissionReceivedLastWeek, "", 2)
            }}$</span>
        </p>
        <p>
          {{ $t("affiliate.expectCommissionWeek") }}:
          <span>
            {{
              TruncateToDecimals2(+Statistic.expectedCommissionWeek, "", 2)
            }}$</span>
        </p>
      </div>
    </div>
    <div class="affiliate-info">
      <div class="affiliate-info-item">
        <div class="statistical-member">
          <div class="statistical-member-item">
            <div class="d-flex justify-content-between align-items-center">
              <div class="box-member">
                <h3>{{ $t("affiliate.numberMember") }}</h3>
                <hr />
                <p>{{ $t("affiliate.totalFriend") }}</p>
                <p>
                  <span class="amount">{{
                    TruncateToDecimals2(+Statistic.totalMember, "", 2)
                  }}</span>
                </p>
                <p>
                  {{ $t("affiliate.activeDownline") }}:
                  <span>{{
                    TruncateToDecimals2(+Statistic.totalDownLineActive, "", 2)
                  }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="statistical-member-item">
            <div class="reflink">
              <div class="box-qr">
                <img :src="`https://chart.googleapis.com/chart?chs=400x400&cht=qr&chl=${Statistic.link_ref}&choe=UTF-8`"
                  alt="" />
              </div>
              <b-form-input :value="LinkRefShow" readonly class="custom-input"></b-form-input>
              <b-button @click="onCopy" class="btnNoStyle btn-copy">
                <img src="~@/assets/images/icons/icon-copy.png" alt="" />
                {{ $t("agency.copy") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="affiliate-info-item">
        <p>{{ $t("expert.Total") }} Stake {{ $t("happy.system") }}</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+Statistic.totalStakeSystem, "", 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
      <div class="affiliate-info-item">
        <p>{{ $t("expert.Total") }} Wow</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+Statistic.totalBet, "", 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
      <div class="affiliate-info-item">
        <p>{{ $t("affiliate.totalProfit") }}</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+Statistic.totalProfit, "", 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
      <div class="affiliate-info-item">
        <p>{{ $t("agency.total_commission") }}</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+Statistic.totalCommission, "", 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
      <div class="affiliate-info-item">
        <p>{{ $t("affiliate.totalDeposit") }}</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+Statistic.totalDeposit, "", 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
      <div class="affiliate-info-item">
        <p>{{ $t("affiliate.totalWithdraw") }}</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+Statistic.totalWithdraw, "", 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
      <div class="affiliate-info-item">
        <p>Total System Stake/Last Week</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+Statistic.totalSystemStakeLastWeek, "", 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
      <div class="affiliate-info-item">
        <p>Total System Stake/This Week</p>
        <hr />
        <div class="amount">
          <p>{{ TruncateToDecimals2(+Statistic.totalSystemStakeThisWeek, "", 2) }}</p>
          <img src="~@/assets/images/currency/usd.png" alt="" />
        </div>
      </div>
    </div>
    <div class="stake-history">
      <div class="tabs-wrapper">
        <button @click="activeTab = 'list'" class="botan gradient-border withBG withBlur"
          :class="{ active: activeTab === 'list' }">
          {{ $t("affiliate.memberList") }}
        </button>
        <button @click="activeTab = 'tree'" class="botan gradient-border withBG withBlur"
          :class="{ active: activeTab === 'tree' }">
          {{ $t("affiliate.memberTree") }}
        </button>
      </div>
      <div class="historyTable" v-if="activeTab === 'list'">
        <div class="table-responsive" v-if="!showTableMobile">
          <b-table :items="MemberList" :fields="fields" show-empty class="myTable">
            <template #empty="">
              <h5 class="text-center p-2">
                {{ $t("support.no_data_available_in_table") }}
              </h5>
            </template>
            <template #cell(totalBet)="data">
              <div class="d-flex align-items-center justify-content-center">
                {{ TruncateToDecimals2(+data.item.totalBet, "", 2) }}
              </div>
            </template>
            <template #cell(totalBetLastWeek)="data">
              <div class="d-flex align-items-center justify-content-center">
                {{ TruncateToDecimals2(+data.item.totalBetLastWeek, "", 2) }}
              </div>
            </template>
            <template #cell(totalStakeSystem)="data">
              <div class="d-flex align-items-center justify-content-center">
                {{ TruncateToDecimals2(+data.item.totalStakeSystem, "", 2) }}
              </div>
            </template>
            <template #cell(totalInvestment)="data">
              <div class="d-flex align-items-center justify-content-center">
                {{ TruncateToDecimals2(+data.item.totalInvestment, "", 2) }}
              </div>
            </template>
            <template #cell(totalProfit)="data">
              <div class="d-flex align-items-center justify-content-center">
                {{ TruncateToDecimals2(+data.item.totalProfit, "", 2) }}
              </div>
            </template>
            <template #cell(totalDeposit)="data">
              <div class="d-flex align-items-center justify-content-center">
                {{ TruncateToDecimals2(+data.item.totalDeposit, "", 2) }}
              </div>
            </template>
            <template #cell(totalWithdraw)="data">
              <div class="d-flex align-items-center justify-content-center">
                {{ TruncateToDecimals2(+data.item.totalWithdraw, "", 2) }}
              </div>
            </template>
          </b-table>
        </div>
        <div class="table-mobile" v-else>
          <template v-if="MemberList && MemberList.length > 0">
            <div v-for="(member, idx) in MemberList" :key="'profitHistory: ' + idx" class="w-100" role="tablist">
              <b-button v-b-toggle="`profitHistory-${idx}`" class="btnNoStyle header" role="tab">{{ $t("affiliate.friend")
              }}: {{ member.username }}</b-button>
              <b-collapse :id="`profitHistory-${idx}`" class="mt-2" accordion="my-accordion" role="tabpanel">
                <ul>
                  <li>ID: {{ member.userId }}</li>
                  <li>{{ $t("agency.email") }}: {{ member.email }}</li>
                  <li>F: {{ member.f }}</li>
                  <li>{{ $t("auth.sponsor") }}: {{ member.ponser }}</li>
                  <li>
                    {{ $t("expert.Total") }} Stake {{ $t("happy.system") }}:
                    {{ TruncateToDecimals2(+member.totalStakeSystem, "", 2) }}
                  </li>
                  <li>
                    {{ $t("expert.Total") }} Stake:
                    {{ TruncateToDecimals2(+member.totalInvestment, "", 2) }}
                  </li>
                  <li>
                    {{ $t("expert.Total") }} Wow:
                    {{ TruncateToDecimals2(+member.totalBet, "", 2) }}
                  </li>
                  <li>
                    {{ $t("expert.Total") }} Wow Last Week:
                    {{ TruncateToDecimals2(+member.totalBetLastWeek, "", 2) }}
                  </li>
                  <li>
                    {{ $t("affiliate.totalProfit") }}:
                    {{ TruncateToDecimals2(+member.totalProfit, "", 2) }}
                  </li>
                  <li>
                    {{ $t("affiliate.totalDeposit") }}:
                    {{ TruncateToDecimals2(+member.totalDeposit, "", 2) }}
                  </li>
                  <li>
                    {{ $t("affiliate.totalWithdraw") }}:
                    {{ TruncateToDecimals2(+member.totalWithdraw, "", 2) }}
                  </li>
                  <li>
                    {{ $t("affiliate.registerDate") }}:
                    {{ member.registeredDatetime }}
                  </li>
                </ul>
              </b-collapse>
            </div>
          </template>
          <p v-else class="text-center mb-0">
            {{ $t("support.no_data_available_in_table") }}
          </p>
        </div>
        <div class="d-flex w-100 justify-content-end align-items-center">
          <paginate :totalPage="TotalPage" @current="onChangePageHistory" />
        </div>
      </div>
      <div class="historyTable" v-if="activeTab === 'tree'">
        <TreeNode :node="Node" />
      </div>
    </div>
    <b-modal id="member-data" ref="userProfile" title="" hide-footer hide-header body-class="p-0" header-class="p-0">
      <div class="member-data" v-if="Member.infor">
        <div class="data-header">
          <p>{{ $t("agency.member") }}: {{ Member.infor.username }}</p>
          <b-button class="btnNoStyle" @click="$bvModal.hide('member-data')">
            <img src="@/assets/images/icons/close-white.svg" alt="" />
          </b-button>
        </div>
        <div class="data-body">
          <div class="data-points">
            <span> {{ $t("agency.email") }}: </span>
            <div class="input-box">
              {{ Member.infor.email }}
            </div>
          </div>
          <div class="data-points">
            <span> {{ $t("auth.sponsor") }}: </span>
            <div class="input-box">
              {{ Member.infor.ponser }}
            </div>
          </div>
          <div class="data-points">
            <span> Id: </span>
            <div class="input-box">
              {{ Member.infor.userId }}
            </div>
          </div>
          <div class="data-points">
            <span> {{ $t("expert.Total") }} Stake {{ $t("happy.system") }} : </span>
            <div class="input-box">
              {{ Member.totalStakeSystem }}
            </div>
          </div>
          <div class="data-points">
            <span> Stake: </span>
            <div class="input-box">
              {{ Member.stake }}
            </div>
          </div>
          <div class="data-points">
            <span> {{ $t("expert.Total") }} Wow: </span>
            <div class="input-box">
              {{ Member.totalBet }}
            </div>
          </div>
          <div class="data-points">
            <span> {{ $t("expert.Total") }} Wow Last Week: </span>
            <div class="input-box">
              {{ Member.totalBetLastWeek }}
            </div>
          </div>
          <div class="data-points">
            <span> {{ $t("agency.total_commission") }}: </span>
            <div class="input-box">
              {{ Member.totalCommission }}
            </div>
          </div>
          <div class="data-points">
            <span> {{ $t("affiliate.totalDeposit") }}: </span>
            <div class="input-box">
              {{ Member.totalDeposit }}
            </div>
          </div>
          <div class="data-points">
            <span>{{ $t("affiliate.totalProfit") }}: </span>
            <div class="input-box">
              {{ Member.totalProfit }}
            </div>
          </div>
          <div class="data-points">
            <span> {{ $t("affiliate.totalWithdraw") }}: </span>
            <div class="input-box">
              {{ Member.totalWithdraw }}
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import TreeNode from "../components/TreeNode.vue";
import Paginate from "@/components/Shared/Pagination.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Paginate,
    TreeNode,
  },
  data() {
    return {
      widthL: 0,
      showTableMobile: false,
      activeTab: "list",
    };
  },
  methods: {
    onChangePageHistory(payload) {
      this.$store.dispatch("member/req_getMemberList", { page: payload });
    },
    checkResizeWindow() {
      this.widthL = window.innerWidth;
    },
    onCopy() {
      navigator.clipboard.writeText(
        `${window.location.host}/login?sponsor=${this.UserInfo.userId}`
      );
      this.$toastr.s(
        `${window.location.host}/login?sponsor=${this.UserInfo.userId}`,
        "Link ref " + this.$t("affiliate.copied")
      );
    },
  },
  computed: {
    ...mapGetters({
      Statistic: "member/Statistic",
      MemberList: "member/List",
      Node: "member/Tree",
      TotalPage: "member/ListPage",
      UserInfo: "userinfo/UserInfo",
      Member: "member/Member",
    }),

    fields() {
      return [
        {
          key: "userId",
          label: "ID",
          class: "",
        },
        {
          key: "email",
          label: this.$t("agency.email"),
          class: "text-center",
        },
        {
          key: "f",
          label: "F",
          class: "text-center",
        },
        {
          key: "ponser",
          label: this.$t("auth.sponsor"),
          class: "text-center",
        },
        {
          key: "totalStakeSystem",
          label: this.$t("expert.Total") + " Stake " + this.$t("happy.system"),
          class: "text-center",
        },
        {
          key: "totalInvestment",
          label: this.$t("expert.Total") + " Stake",
          class: "text-center",
        },
        {
          key: "totalBet",
          label: this.$t("expert.Total") + " Wow",
          class: "text-center",
        },
        {
          key: "totalBetLastWeek",
          label: this.$t("expert.Total") + " Wow Last Week",
          class: "text-center",
        },
        {
          key: "totalProfit",
          label: this.$t("affiliate.totalProfit"),
          class: "text-center",
        },
        {
          key: "totalDeposit",
          label: this.$t("affiliate.totalDeposit"),
          class: "text-center",
        },
        {
          key: "totalWithdraw",
          label: this.$t("affiliate.totalWithdraw"),
          class: "text-center",
        },
        {
          key: "registeredDatetime",
          label: this.$t("affiliate.registerDate"),
          class: "text-center",
        },
      ];
    },

    LinkRefShow() {
      const addressCurrent = `${window.location.host}/login?sponsor=${this.UserInfo.userId}`;
      if (!addressCurrent) return null;
      const stringShort = `${addressCurrent.slice(
        0,
        5
      )}...${addressCurrent.slice(
        addressCurrent.length - 5,
        addressCurrent.length
      )}`;
      return stringShort;
    },

    VolumePercent() {
      if (this.Statistic && this.Statistic.agencyNext) {
        return (
          parseFloat(this.Statistic.totalBetSystem) /
          parseFloat(this.Statistic.agencyNext.total_volume)
        );
      }
      return 0;
    },

    UserPercent() {
      if (this.Statistic && this.Statistic.totalBetUser) {
        return (
          parseFloat(this.Statistic.totalBetUser) /
          parseFloat(this.Statistic.agencyNext.trade_min)
        );
      }
      return 0;
    },

    DownlinePercent() {
      if (this.Statistic && !isNaN(this.Statistic.totalDownLineActive)) {
        const tot = parseFloat(this.Statistic.agencyNext.count_f1);
        const cur = parseFloat(this.Statistic.totalDownLineActive);

        return cur / tot;
      }
      return 0;
    },
  },
  watch: {
    activeTab: {
      handler(newVal) {
        if (newVal) {
          if (newVal === "tree") {
            this.$store.dispatch("member/req_getTree");
          }
        }
      },
    },
  },
  mounted() {
    this.checkResizeWindow();
    this.windowEvent = () => {
      this.checkResizeWindow();
    };
    if (this.widthL <= 576) {
      this.showTableMobile = true;
    } else {
      this.showTableMobile = false;
    }
  },
  created() {
    this.$store.dispatch("member/req_getStatistic");
    this.$store.dispatch("member/req_getMemberList");
  },
};
</script>

<style lang="scss" scoped>
.affiliatecc {
  padding: 30px 50px;

  @media (max-width: 992px) {
    padding: 30px 20px;
  }

  @media (max-width: 576px) {
    padding: 30px 10px;
  }

  .affiliate-info-item {
    background: #081b3c;
    border-radius: 8px;
    padding: 20px 10px;
    color: #bebebe;

    &.agency {
      margin: auto;
      margin-bottom: 30px;
      max-width: 700px;

      .agency-img {
        display: flex;
        width: fit-content;
        margin: auto;
        padding: 10px 0px;

        .agency-wrap {
          text-align: center;
          display: flex;
          flex-direction: column;

          img {
            flex-grow: 1;
            max-width: 100px;
            object-fit: contain;
          }
        }

        .upgrade {
          margin: 0px 10px;
        }
      }

      .gradient-text {
        font-weight: 700;
      }

      .agency-progress {
        margin-bottom: 15px;
        --progress: 0.3;

        .bar {
          font-size: 12px;
          width: 100%;
          min-height: 16px;
          margin-top: 8px;
          background-color: #23367a;
          border-radius: 3px;
          position: relative;
          text-align: center;
          z-index: 2;

          span {
            line-height: 0;
          }

          &::before {
            content: "";
            position: absolute;
            z-index: -1;
            border-radius: inherit;
            inset: 2px;
            width: calc(100% * var(--progress) - 4px);
            max-width: calc(100% - 4px);
            background-color: #00b3ff;
          }
        }
      }

      @media (min-width: 576px) {
        padding: 20px;

        .agency-img {
          padding: 0px;

          .upgrade {
            margin: 0px 40px;
          }
        }

        .agency-progress {
          margin-bottom: 30px;
        }
      }

      .bonus {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        p {
          margin-bottom: 0px;
          animation: pulsate 2s infinite alternate;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0px;
          }

          @media (max-width: 375px) {
            font-size: 14px;
          }

          span {
            font-weight: bold;
            background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-size: 18px;
          }
        }

        @keyframes pulsate {
          0% {
            text-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px #fff,
              0 0 5px #ffe500, 0 0 10px #ffe500, 0 0 20px #ffe500,
              0 0 25px #ffe500, 0 0 35px #ffe500;
          }
        }
      }
    }
  }

  .affiliate-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px 200px;
    height: 100%;

    @media (max-width: 1700px) {
      padding: 0px 100px;
    }

    @media (max-width: 1500px) {
      padding: 0px 0px;
    }

    .affiliate-info-item {
      margin-bottom: 30px;
      width: 32%;
      height: 200px;

      @media (max-width: 1200px) {
        width: 48%;
      }

      @media (max-width: 768px) {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 376px) {
        padding: 20px 10px;
      }

      p {
        color: #bebebe;
        font-size: 18px;
        font-weight: 400;

        @media (max-width: 1400px) {
          font-size: 16px;
        }

        @media (max-width: 576px) {
          font-size: 14px;
        }
      }

      hr {
        border: 1px solid #404040;
        margin-bottom: 30px;
      }

      .amount {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 30px;
          margin-bottom: 0px;
          background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          font-weight: bold;

          @media (max-width: 1400px) {
            font-size: 22px;
          }

          @media (max-width: 576px) {
            font-size: 18px;
          }
        }

        img {
          width: 30px;

          @media (max-width: 1400px) {
            width: 25px;
          }
        }
      }

      .statistical-member {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 376px) {
          flex-direction: column;
        }

        .statistical-member-item {
          width: 48%;

          @media (max-width: 1400px) {
            &:nth-child(1) {
              width: 44%;
            }

            &:nth-child(2) {
              width: 54%;
            }
          }

          @media (max-width: 576px) {
            &:nth-child(1) {
              width: 48%;
            }

            &:nth-child(2) {
              width: 48%;
              margin-top: 20px;
            }
          }

          @media (max-width: 440px) {
            &:nth-child(1) {
              width: 43%;
            }

            &:nth-child(2) {
              width: 55%;
              margin-top: 20px;
            }
          }

          @media (max-width: 376px) {
            &:nth-child(1) {
              width: 100%;
            }

            &:nth-child(2) {
              width: 100%;
              margin-top: 20px;
            }
          }

          .box-member {
            @media (max-width: 376px) {
              width: 100%;
            }

            h3 {
              color: #bebebe;
              font-size: 18px;
              margin-bottom: 15px;

              @media (max-width: 992px) {
                font-size: 16px;
              }
            }

            hr {
              width: 100%;
              border: 1px solid #404040;
              margin-top: 0px;
              margin-bottom: 15px;

              @media (max-width: 992px) {
                display: none;
              }
            }

            p {
              color: #ffffff;
              margin-bottom: 15px;
              margin-top: 0px;
              line-height: 100%;

              @media (max-width: 992px) {
                font-size: 14px;
              }

              .amount {
                background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-size: 22px;
                font-weight: bold;

                @media (max-width: 992px) {
                  font-size: 18px;
                }
              }

              &:last-child {
                margin-bottom: 0px;
              }

              span {
                background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                font-size: 22px;
                font-weight: bold;
              }
            }
          }

          .reflink {
            text-align: center;
            position: relative;

            input {
              margin: 15px 0px;
              font-size: 14px;
            }

            .box-qr {
              img {
                width: 100px;

                @media (max-width: 1200px) {
                  width: 80px;
                }
              }
            }

            .copy-ref {
              width: 100%;
              height: 50px;
              background: linear-gradient(91.46deg,
                  #0ec5ff 1.08%,
                  #3964fb 83%) !important;
              border-radius: 3px;
              position: relative;
              z-index: 1;
              font-weight: bold;
              font-size: 20px;

              @media (max-width: 576px) {
                font-size: 16px;
              }

              &::before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 50px;
                background: linear-gradient(91.46deg,
                    #0ec5ff 1.08%,
                    #3964fb 83%);
                filter: blur(21px);
                z-index: -1;
              }
            }

            .btn-copy {
              position: absolute;
              bottom: 8px;
              right: 5px;
              font-size: 14px;

              img {
                width: 17px;
                margin-right: -5px;
              }
            }
          }
        }
      }
    }
  }

  .stake-history {
    margin-top: 50px;

    .tabs-wrapper {
      width: fit-content;
      margin-bottom: 40px;

      .botan {
        padding: 15px 30px;

        @media (max-width: 576px) {
          padding: 5px 20px;
        }
      }
    }

    .title {
      font-size: 30px;
      background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin-bottom: 20px;

      @media (max-width: 576px) {
        font-size: 22px;
      }
    }

    .stake-history-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      @media (max-width: 440px) {
        flex-direction: column;
      }

      .group-search {
        display: flex;
        align-items: center;

        @media (max-width: 440px) {
          margin-top: 20px;
        }

        .custom-input {
          width: 200px;
          height: 40px;

          @media (max-width: 992px) {
            font-size: 12px !important;
            width: 140px;
            height: 30px;
          }
        }
      }
    }

    .historyTable {
      background: #081b3c;
      border-radius: 20px;
      padding: 20px;
      overflow: auto;

      .wallet-tab {
        padding: 5px;
        background: rgba(27, 72, 187, 0.2);
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(34px);
        border-radius: 7px;
        width: fit-content;
        margin-bottom: 40px;
        overflow-x: auto;
        overflow-y: hidden;

        @media (max-width: 576px) {
          width: 100%;
        }

        .listTab {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .tab-item {
            padding: 0px 25px;
            min-height: 55px;

            @media (max-width: 1400px) {
              font-size: 14px;
              height: 35px;
            }

            @media (max-width: 576px) {
              min-width: 130px;
            }

            &.activeTab {
              background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
              border-radius: 6px;
            }
          }
        }
      }
    }

    .table-mobile {
      width: 100%;
      background: linear-gradient(180deg,
          #081e43 0%,
          rgba(1, 6, 15, 0.63) 100%);
      border-radius: 4px;
      padding: 20px;

      .header {
        width: 100%;
        background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
        border-radius: 6px;
        padding: 0px 25px;
        height: 55px;
        margin-bottom: 20px;
      }

      .collapse {
        margin-top: -20px !important;
        padding: 20px 10px;
        box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
        -webkit-backdrop-filter: blur(34px);
        backdrop-filter: blur(34px);
        border-radius: 7px;
        margin-bottom: 20px;

        P {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.member-data {
  .data-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--header-1,
        linear-gradient(135deg, #00041b 0%, #000937 100%));
    padding: 16px 23px;

    p {
      color: #ffffff;
      margin-bottom: 0px;
      font-weight: 500;
      font-size: 18px;
    }

    button {
      padding: 0px;
      color: #6a6a6a;
    }
  }

  span {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
  }

  .data-points {
    margin-bottom: 5px;
  }

  .input-box {
    padding: 5px;
  }

  .data-body {
    padding: 20px 10px;

    @media (min-width: 576px) {
      padding: 20px;
    }
  }
}
</style>
